import { gql } from '@apollo/client'

const TOGGLE_EVENT_INTEREST = gql`
  mutation ToggleEventInterest($eventId: ID!, $shouldShowInterest: Boolean!) {
    toggleEventInterest(
      eventId: $eventId
      shouldShowInterest: $shouldShowInterest
    ) {
      id
      isInterested
      interestedCount
    }
  }
`

export default TOGGLE_EVENT_INTEREST
