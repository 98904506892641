const useGetImageProperties = (imageType, baseProperties = {}) => {
  if (imageType === PROFILE_IMAGE_TYPES.face) {
    return {
      ...baseProperties,
      rt: 'fill',
      gravity: 'sm',
    }
  }

  return {
    ...baseProperties,
    extend: '1',
    rt: 'fill',
    enlarge: '1',
  }
}

const PROFILE_IMAGE_DIMENSIONS = {
  desktop: {
    h: 180,
    w: 180,
  },
  mobile: {
    h: 130,
    w: 130,
  },
}

const PROFILE_IMAGE_TYPES = Object.freeze({
  face: 'face',
  logo: 'logo',
})

type ProfileImageType =
  typeof PROFILE_IMAGE_TYPES[keyof typeof PROFILE_IMAGE_TYPES]

export type { ProfileImageType }
export { useGetImageProperties, PROFILE_IMAGE_DIMENSIONS, PROFILE_IMAGE_TYPES }
