import { useMutation } from '@apollo/client'
import GET_EVENT_DETAIL from 'components/events/pages/event-detail/GetEventDetailQuery'
import TOGGLE_EVENT_INTEREST from 'components/events/pages/event-detail/interest-button/ToggleEventInterestQuery'
import { useFeatureSwitch } from 'context/FeatureSwitchesContext'
import { useUserContext } from 'context/UserContext'
import featureSwitches from 'enums/feature-switches'

const constrainCount = (newFollowerCount) => Math.max(0, newFollowerCount)

const useSavedEventsToggleInterest = (
  eventId: string | null,
  isInterested: boolean,
  interestedCount: number
) => {
  const { isAuthenticated } = useUserContext()
  const enablePresalePage = useFeatureSwitch(featureSwitches.enablePresalePage)

  const optimisticResponse = {
    __typename: 'Mutation',
    toggleEventInterest: {
      id: eventId,
      interestedCount: isInterested
        ? constrainCount(interestedCount - 1)
        : interestedCount + 1,
      isInterested: !isInterested,
      __typename: 'Event',
    },
  }

  const [toggle] = useMutation(TOGGLE_EVENT_INTEREST, {
    variables: { eventId, shouldShowInterest: !isInterested },
    refetchQueries: [
      {
        query: GET_EVENT_DETAIL,
        variables: {
          id: eventId,
          isAuthenticated,
          canAccessPresale: enablePresalePage && isAuthenticated,
        },
      },
    ],
    optimisticResponse,
  })

  return { toggle }
}

export default useSavedEventsToggleInterest
