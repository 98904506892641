import PropTypes from 'prop-types'
import styled, { ThemeProvider } from 'styled-components'
import { Layout, Alignment } from '@resident-advisor/design-system'
import { dark, headers } from 'themes'
import { HeaderContent, HeaderWrapper } from 'components/generic/header'
import { hrefAsPropTypes } from 'components/generic/link'
import { PROFILE_IMAGE_TYPES } from 'lib/profileImageUtils'
import useMenuColors from 'hooks/useMenuColors'
import { useFeatureSwitch } from 'context/FeatureSwitchesContext'
import featureSwitches from 'enums/feature-switches'
import ProfileImage from '../profile-image/ProfileImage'

const ProfileHeader = ({
  backgroundImage,
  breadcrumb,
  smallImage,
  smallImageType,
  tags,
  text,
  noBlur,
  isArchivedPage,
  bg,
}) => {
  useMenuColors(bg)
  const enableNewMainNav = useFeatureSwitch(featureSwitches.enableNewMainNav)

  return (
    <ThemeProvider theme={dark}>
      <HeaderWrapper imageUrl={backgroundImage} blur={!noBlur} bg={bg}>
        <FullHeightLayout>
          <HeaderContentWrapper
            alignItems={{ s: 'flex-start', m: 'flex-end' }}
            flexDirection={{ s: 'column-reverse', m: 'row' }}
            justifyContent="space-between"
            mt={enableNewMainNav && headers.default.mt}
            pt={headers.profile.pt}
            pb={headers.profile.pb}
            minHeight={headers.profile.minHeight}
          >
            <Alignment justifyContent="flex-end" width="100%">
              <HeaderContent
                breadcrumb={breadcrumb}
                tags={tags}
                text={text}
                isArchivedPage={isArchivedPage}
              />
            </Alignment>
            <Alignment
              mb={headers.profile.photoMb}
              ml={headers.profile.photoMl}
            >
              {smallImage && (
                <ProfileImage
                  url={smallImage}
                  name={text}
                  type={smallImageType}
                />
              )}
            </Alignment>
          </HeaderContentWrapper>
        </FullHeightLayout>
      </HeaderWrapper>
    </ThemeProvider>
  )
}

const FullHeightLayout = styled(Layout)`
  height: 100%;
`

const HeaderContentWrapper = styled(Alignment)`
  box-sizing: border-box;
  position: relative;
`

ProfileHeader.propTypes = {
  backgroundImage: PropTypes.string,
  breadcrumb: PropTypes.arrayOf(
    PropTypes.shape({
      href: hrefAsPropTypes.isRequired,
      as: hrefAsPropTypes,
      text: PropTypes.string.isRequired,
      icon: PropTypes.node,
    })
  ),
  smallImage: PropTypes.string,
  smallImageType: PropTypes.oneOf(Object.values(PROFILE_IMAGE_TYPES)),
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      href: hrefAsPropTypes.isRequired,
      as: hrefAsPropTypes,
      text: PropTypes.string.isRequired,
    })
  ),
  text: PropTypes.string.isRequired,
  noBlur: PropTypes.bool,
  isArchivedPage: PropTypes.bool,
  bg: PropTypes.string,
}

export default ProfileHeader
