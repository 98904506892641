import { Box } from '@resident-advisor/design-system'
import ResponsiveImage, {
  ResponsiveImageDimension,
  ResponsiveImageSizes,
} from 'components/generic/responsive-image/ResponsiveImage'
import {
  useGetImageProperties,
  PROFILE_IMAGE_DIMENSIONS,
  ProfileImageType,
} from 'lib/profileImageUtils'
import { LayoutProps } from 'styled-system'

const ProfileImage = ({
  url,
  name,
  width = {
    s: `${PROFILE_IMAGE_DIMENSIONS.mobile.w}px`,
    l: `${PROFILE_IMAGE_DIMENSIONS.desktop.w}px`,
  },
  height,
  type = 'face',
  sizes,
  dimensions = [PROFILE_IMAGE_DIMENSIONS.desktop],
  borderRadius = 'circular',
}: ProfileImageProps) => {
  const properties = useGetImageProperties(type)

  return (
    <Box height={height} width={width}>
      <ResponsiveImage
        borderRadius={borderRadius}
        url={url}
        properties={properties}
        alt={`${name} profile image`}
        dimensions={dimensions}
        sizes={sizes}
        height={height}
      />
    </Box>
  )
}

type ProfileImageProps = {
  url: string
  name: string
  width?: LayoutProps['width']
  height?: LayoutProps['height']
  type?: ProfileImageType
  dimensions?: ResponsiveImageDimension[]
  sizes?: ResponsiveImageSizes
  borderRadius?: string
}

export default ProfileImage
