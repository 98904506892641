import { defineMessages } from 'react-intl'

const messages = defineMessages({
  music: {
    id: 'Music.Heading',
    defaultMessage: 'Music',
  },
  albums: {
    id: 'Music.Albums',
    defaultMessage: 'Albums',
  },
  latestAlbums: {
    id: 'Music.LatestAlbums',
    defaultMessage: 'Latest Albums',
  },
  singles: {
    id: 'Music.Singles',
    defaultMessage: 'Singles',
  },
  latestSinglesAndEPs: {
    id: 'Music.LatestSinglesAndEPs',
    defaultMessage: 'Latest Singles & EPs',
  },
  events: {
    id: 'Music.Events',
    defaultMessage: 'Events',
  },
  eventReviews: {
    id: 'Music.EventReviews',
    defaultMessage: 'Event Reviews',
    description: 'Heading for event reviews page',
  },
  tech: {
    id: 'Music.Tech',
    defaultMessage: 'Tech',
  },
  podcast: {
    id: 'Music.Podcast',
    defaultMessage: 'RA Podcast',
  },
  thisWeeksPodcast: {
    id: 'Music.ThisWeeksPodcast',
    defaultMessage: "This Week's RA Podcast",
  },
  latestPodcasts: {
    id: 'Music.LatestPodcasts',
    defaultMessage: 'Latest Podcasts',
  },
  viewMorePodcasts: {
    id: 'Music.ViewMorePodcasts',
    defaultMessage: 'View more podcasts',
  },
  exchange: {
    id: 'Music.Exchange',
    defaultMessage: 'RA Exchange',
  },
  thisWeeksExchange: {
    id: 'Music.ThisWeeksExchange',
    defaultMessage: "This Week's RA Exchange",
  },
  latestExchanges: {
    id: 'Music.LatestExchanges',
    defaultMessage: 'Latest Exchanges',
  },
  viewMoreExchanges: {
    id: 'Music.ViewMoreExchanges',
    defaultMessage: 'View more exchanges',
  },
  reviews: {
    id: 'Music.Reviews',
    defaultMessage: 'Reviews',
  },
  viewAllAlbums: {
    id: 'Music.ViewAllAlbums',
    defaultMessage: 'View all albums',
  },
  viewMoreSingles: {
    id: 'Music.ViewMoreSingles',
    defaultMessage: 'View more singles',
  },
  musicReviews: {
    id: 'Music.musicReviews',
    defaultMessage: 'Music Reviews',
  },
  viewMoreReviews: {
    id: 'Music.ViewMoreReviews',
    defaultMessage: 'View more reviews',
  },
  viewAllSinglesAndEPs: {
    id: 'Music.ViewAllSinglesAndEPs',
    defaultMessage: 'View all singles & EPs',
  },
  recentPlaylists: {
    id: 'Music.RecentPlaylists',
    defaultMessage: 'Recent Playlists',
  },
  recentMixes: {
    id: 'Music.RecentMixes',
    defaultMessage: 'Recent Mixes',
  },
  latestMix: {
    id: 'Music.LatestMix',
    defaultMessage: 'Latest Mix',
  },
  mixOfTheDay: {
    id: 'Music.MixOfTheDay',
    defaultMessage: 'Mix of the day',
  },
  viewMoreMixes: {
    id: 'Music.ViewMoreMixes',
    defaultMessage: 'View more mixes',
  },
  playlistOfTheDay: {
    id: 'Music.PlaylistOfTheDay',
    defaultMessage: 'Playlist of the day',
  },
  viewAllPlaylists: {
    id: 'Music.ViewAllPlaylists',
    defaultMessage: 'View all playlists',
  },
  recommends: {
    id: 'Music.Recommends',
    defaultMessage: 'RA Recommends',
  },
  viewMoreRecommendations: {
    id: 'Music.ViewMoreRecommendations',
    defaultMessage: 'View more RA Recommends',
  },
  popular: {
    id: 'Music.Popular',
    defaultMessage: 'Popular',
  },
  viewMorePopular: {
    id: 'Music.ViewMorePopular',
    defaultMessage: 'View more popular',
  },
  playlists: {
    id: 'Music.Playlists',
    defaultMessage: 'Playlists',
  },
  latestPlaylists: {
    id: 'Music.LatestPlaylists',
    defaultMessage: 'Latest Playlists',
  },
  followUsOn: {
    id: 'Music.FollowUsOn',
    defaultMessage: 'Follow us on',
  },
  genre: {
    id: 'Music.Genre',
    defaultMessage: 'Genre',
    description: 'Style of music',
  },
  allGenres: {
    id: 'Music.AllGenres',
    defaultMessage: 'All genres',
  },
  relatedArtists: {
    id: 'Music.RelatedArtists',
    defaultMessage: 'Related Artists',
    description: 'heading for related artists sub-section',
  },
  artists: {
    id: 'Music.Artists',
    defaultMessage: 'Artists',
    description: 'For artists on a Label',
  },
  popularReviews: {
    id: 'Music.PopularReviews',
    defaultMessage: 'Popular Reviews',
    description: 'Heading for popular reviews sub-section',
  },
  published: {
    id: 'Music.Published',
    defaultMessage: 'Published',
  },
  fileSize: {
    id: 'Music.FileSize',
    defaultMessage: 'Filesize',
  },
  length: {
    id: 'Music.Length',
    defaultMessage: 'Length',
    description: 'Heading for length/duration of music content',
  },
  mixes: {
    id: 'Music.Mixes',
    defaultMessage: 'Mixes',
    description: 'Heading for mixes sub-section',
  },
  year: {
    id: 'Music.Year',
    defaultMessage: 'Year',
    description: 'Heading of the year filter',
  },
  month: {
    id: 'Music.Month',
    defaultMessage: 'Month',
    description: 'Heading of the month filter',
  },
  archiveTitle: {
    id: 'Music.ArchiveTitle',
    defaultMessage: '{genre} in {date}',
    description:
      'Heading of the reviews archive section e.g. Techno in November 2018',
  },
  musicSummary: {
    id: 'Music.Summary',
    defaultMessage: 'Everything you need to hear in electronic music.',
    description: 'Header Summary/explanation of content on the music page',
  },
  mixOfTheDaySummary: {
    id: 'Music.MixOfTheDaySummary',
    defaultMessage: 'Daily mixes from the world of electronic music.',
    description:
      'Header Summary/explanation of content on the mix-of-the-day page',
  },
  playlistsSummary: {
    id: 'Music.PlaylistSummary',
    defaultMessage:
      "RA's playlists showcase the best music from the past, present and future of electronic music.",
    description: 'Header Summary/explanation of content on the playlist page',
  },
  exchangeSummary: {
    id: 'Music.ExchangeSummary',
    defaultMessage:
      'The weekly RA Exchange is a series of conversations with artists, labels and promoters shaping the electronic music landscape.',
    description: 'Header Summary/explanation of content on the exchange page',
  },
  previousReviews: {
    id: 'Music.PreviousReviews',
    defaultMessage: 'Previous Reviews',
  },
  nextReviews: {
    id: 'Music.NextReviews',
    defaultMessage: 'Next Reviews',
  },
  albumReview: {
    id: 'Music.AlbumReview',
    defaultMessage: 'Album Review',
  },
  epReview: {
    id: 'Music.EPReview',
    defaultMessage: 'EP Review',
  },
})

export default messages
