import arrayHasData from 'lib/arrayHasData'
import TICKET_VALID_TYPE from 'enums/ticket-valid-type'
import TicketDto from 'interfaces/gql/TicketDto'
import ArtistDto from 'interfaces/gql/ArtistDto'
import PromoterDto from 'interfaces/gql/PromoterDto'
import VenueDto from 'interfaces/gql/VenueDto'
import { EventSavedSourceType } from './saved-events-button/SavedEventsButton'

const useSavedEventMixpanelProperties = (
  trackingData: {
    venue: VenueDto
    artists: ArtistDto[]
    promoters: PromoterDto[]
    tickets: TicketDto[]
    isTicketed: boolean
    source: EventSavedSourceType
  },
  eventId: string,
  interestedCount: number
) => {
  return {
    data: {
      'Event ID': eventId.toString(),
      'RA Country': trackingData.venue?.area?.country?.name,
      'RA Region': trackingData.venue?.area?.name,
      'Lineup Artist Names': arrayHasData(trackingData.artists)
        ? trackingData.artists?.reduce(
            (acc, artist) => [...acc, artist.name],
            []
          )
        : [],
      'Lineup Artist IDs': arrayHasData(trackingData.artists)
        ? trackingData.artists?.reduce((acc, artist) => [...acc, artist.id], [])
        : [],
      Promoters: arrayHasData(trackingData.promoters)
        ? trackingData.promoters?.reduce(
            (acc, promoter) => [...acc, promoter.id],
            []
          )
        : [],
      'Tickets on sale': eventHasTicketsOnSale(trackingData.tickets),
      'Ticketed Event': trackingData.isTicketed,
      'Interested Count': interestedCount,
      'Sold Out': eventIsSoldOut(trackingData.tickets),
      Source: `Saved from ${trackingData.source}`,
    },
    loading: false,
  }
}

const eventIsSoldOut = (tickets: TicketDto[]) => {
  return !tickets?.some(
    (ticket) => ticket.validType !== TICKET_VALID_TYPE.soldOut
  )
}

const eventHasTicketsOnSale = (tickets: TicketDto[]) => {
  return tickets?.some(
    (ticket) =>
      ticket.onSaleFrom <= new Date() &&
      ticket.onSaleUntil >= new Date() &&
      ticket.validType === TICKET_VALID_TYPE.valid
  )
}

export default useSavedEventMixpanelProperties
export { eventIsSoldOut, eventHasTicketsOnSale }
