/* eslint-disable xss/no-mixed-html */
import { defineMessages } from 'react-intl'

const messages = defineMessages({
  magazine: {
    id: 'Magazine.Heading',
    defaultMessage: 'Magazine',
  },
  features: {
    id: 'Magazine.Features',
    defaultMessage: 'Features',
  },
  feature: {
    id: 'Magazine.Feature',
    defaultMessage: 'Feature',
  },
  films: {
    id: 'Magazine.Films',
    defaultMessage: 'Films',
  },
  featureSeries: {
    id: 'Magazine.FeatureSeries',
    defaultMessage: 'Feature Series',
  },
  filmSeries: {
    id: 'Magazine.FilmSeries',
    defaultMessage: 'Film Series',
  },
  latestTechFeatures: {
    id: 'Magazine.LatestTechFeatures',
    defaultMessage: 'Latest Tech Features',
  },
  latestFeatures: {
    id: 'Magazine.LatestFeatures',
    defaultMessage: 'Latest Features',
  },
  latestFeaturesBySeries: {
    id: 'Magazine.latestFeaturesBySeries',
    defaultMessage: 'Latest {series}',
  },
  viewMoreFeatures: {
    id: 'Magazine.ViewMoreFeatures',
    defaultMessage: 'View more features',
  },
  popularFeatures: {
    id: 'Magazine.PopularFeatures',
    defaultMessage: 'Popular Features',
  },
  popularInYear: {
    id: 'Magazine.PopularInYear',
    defaultMessage: 'Popular in {year}',
  },
  videos: {
    id: 'Magazine.Videos',
    defaultMessage: 'Videos',
  },
  recentFilms: {
    id: 'Magazine.RecentFilms',
    defaultMessage: 'Recent films',
  },
  raLive: {
    id: 'Magazine.RALive',
    defaultMessage: 'RA Live',
  },
  featured: {
    id: 'Magazine.Featured',
    defaultMessage: 'Featured',
  },
  allFilms: {
    id: 'Magazine.AllFilms',
    defaultMessage: 'All films',
  },
  viewMoreFilms: {
    id: 'Magazine.ViewMoreFilms',
    defaultMessage: 'View more films',
  },
  latestFilm: {
    id: 'Magazine.LatestFilm',
    defaultMessage: 'Latest Film',
  },
  latestFilms: {
    id: 'Magazine.LatestFilms',
    defaultMessage: 'Latest Films',
  },
  liveVideo: {
    id: 'Magazine.LiveVideo',
    defaultMessage: 'RA LIVE',
    description: 'Videos that have been streamed',
  },
  news: {
    id: 'Magazine.News',
    defaultMessage: 'News',
  },
  newsArticle: {
    id: 'Magazine.NewsArticle',
    defaultMessage: 'News article',
  },
  popularNews: {
    id: 'Magazine.PopularNews',
    defaultMessage: 'Popular News',
  },
  typeNews: {
    id: 'Magazine.TypeNews',
    defaultMessage: '{type} News',
  },
  viewMoreNews: {
    id: 'Magazine.ViewMoreNews',
    defaultMessage: 'View more news',
  },
  popularTypeNews: {
    id: 'Magazine.PopularTypeNews',
    defaultMessage: 'Popular {type} News',
    description: 'Title of popular news subsection for each news type',
  },
  eventReviews: {
    id: 'Magazine.EventReviews',
    defaultMessage: 'Event Reviews',
    description: 'Title of event reviews subsection',
  },
  popularPlaylistNews: {
    id: 'Magazine.PopularLocalNews',
    defaultMessage: 'Popular Local News',
    description: 'Title of the popular local news subsection',
  },
  published: {
    id: 'Magazine.Published',
    defaultMessage: 'Published',
  },
  words: {
    id: 'Magazine.Words',
    defaultMessage: 'Words',
  },
  label: {
    id: 'Magazine.Label',
    defaultMessage: '{labelCount, plural, one {Label} other {Labels}}',
  },
  released: {
    id: 'Magazine.Released',
    defaultMessage: 'Released',
  },
  genre: {
    id: 'Magazine.Genre',
    defaultMessage: '{genreCount, plural, one {Genre} other {Genres}}',
  },
  all: {
    id: 'Magazine.All',
    defaultMessage: 'All',
  },
  local: {
    id: 'Magazine.Local',
    defaultMessage: 'Local',
  },
  international: {
    id: 'Magazine.International',
    defaultMessage: 'International',
  },
  general: {
    id: 'Magazine.General',
    defaultMessage: 'General',
  },
  tech: {
    id: 'Magazine.Tech',
    defaultMessage: 'Tech',
  },
  music: {
    id: 'Magazine.Music',
    defaultMessage: 'Music',
  },
  festival: {
    id: 'Magazine.Festival',
    defaultMessage: 'Festival',
  },
  playlist: {
    id: 'Magazine.Playlist',
    defaultMessage: 'Playlist',
  },
  localNews: {
    id: 'Magazine.LocalNews',
    defaultMessage: 'Local News',
  },
  photoCredit: {
    id: 'Magazine.PhotoCredit',
    defaultMessage: 'Photo Credit',
  },
  series: {
    id: 'Magazine.Series',
    defaultMessage: 'Series',
  },
  aboutRAPodcast: {
    id: 'Magazine.AboutRAPodcast',
    defaultMessage:
      'The weekly RA Podcast features an exclusive mix of electronic music from top producers and DJs around the world.',
  },
  podcastOnItunes: {
    id: 'Magazine.PodcastOnItunes',
    defaultMessage: 'RA Podcast in iTunes',
  },
  podcastXML: {
    id: 'Magazine.PodcastXML',
    defaultMessage: 'RA Podcast XML',
  },
  popularFilms: {
    id: 'Magazine.PopularFilms',
    defaultMessage: 'Popular Films',
  },
  mixoftheday: {
    id: 'Magazine.MixOfTheDay',
    defaultMessage: 'Mix of the day',
  },
  raonra: {
    id: 'Magazine.RAOnRA',
    defaultMessage: 'RA on RA',
  },
  rapro: {
    id: 'Magazine.RAPro',
    defaultMessage: 'RA Pro',
  },
  category: {
    id: 'Magazine.Category',
    defaultMessage: 'Category',
  },
  latest: {
    id: 'Magazine.Latest',
    defaultMessage: 'Latest',
  },
  latestNews: {
    id: 'Magazine.LatestNews',
    defaultMessage: 'Latest News',
  },
  magazineSummary: {
    id: 'Magazine.Summary',
    defaultMessage: 'Interviews, films and news from across the scene.',
    description: 'Header Summary/explanation of content on the magazine page',
  },
  exchangeInItunes: {
    id: 'Magazine.ExchangeOnItunes',
    defaultMessage: 'RA Exchange in iTunes',
  },
  exchangeXML: {
    id: 'Magazine.ExchangeXML',
    defaultMessage: 'RA Exchange XML',
  },
  pitch: {
    id: 'Magazine.Pitch',
    defaultMessage: 'Pitch',
  },
  pitchWithType: {
    id: 'Magazine.PitchWithType',
    defaultMessage: 'Pitch us a {type}',
  },
  review: {
    id: 'Magazine.Review',
    defaultMessage: 'Review',
  },
  pitchThankYou: {
    id: 'Magazine.PitchThankYou',
    defaultMessage:
      'Thank you for submitting your pitch to <i>RA</i>. Due to the large volume of messages we receive, we have to pass on much material of value and interest. If you do not hear from us within five business days, you should feel free to offer it elsewhere.',
  },
  pitchFeature: {
    id: 'Magazine.PitchFeature',
    defaultMessage: `We'd like you to pitch your ideas to us. Login and use the form below for features that form the core of <i>RA</i>'s coverage. <break></break><break></break>We suggest to include four or five sentences explaining why this artist / topic / scene / sound is interesting to you and why our audience should hear about it. We're interested in pitches tied to existing series (Art Of DJing, Breaking Through, etc) as well as standalone ideas.<break></break><break></break>Resident Advisor currently maintains offices in London, Berlin, New York, Los Angeles and Tokyo, but we can't see or hear everything in our tentpole cities, much less locales where we lack access to on-the-ground reporting. As such, all pitches are encouraged, with an emphasis on Black artists and other scenes powered by minority and marginalised communities, without whom dance music would not exist.<break></break><break></break>For more information on a breakdown of our regular feature offering, check out this <contribute>guide to contributing to <i>RA</i></contribute>.<break></break><break></break>You can also read our <integrity>editorial integrity guidelines here</integrity>.`,
  },
  pitchFeatureLinkTitle: {
    id: 'Magazine.PitchFeatureLinkTitle',
    defaultMessage: 'Have an idea for a feature on RA?',
  },
  pitchFeatureLink: {
    id: 'Magazine.PitchFeatureLink',
    defaultMessage: 'Get in touch with us <pitchlink>here</pitchlink>',
  },
  pitchNews: {
    id: 'Magazine.PitchNews',
    defaultMessage: `Do you have some news <i>RA</i> might find valuable? Login and let us know using the form below to explain the news, and why it is something our audience needs to hear about.<break></break><break></break>Resident Advisor currently maintains offices in London, Berlin, New York, Los Angeles and Tokyo, but we can't see or hear everything in our tentpole cities, much less locales where we lack access to on-the-ground reporting. As such, all pitches are encouraged, with an emphasis on Black artists and other scenes powered by minority and marginalised communities, without whom dance music would not exist.<break></break><break></break>For more information on a breakdown of our regular feature offering, check out this <contribute>guide to contributing to <i>RA</i></contribute>.<break></break><break></break>You can also read our <integrity>editorial integrity guidelines here</integrity>.
    `,
  },
  pitchReview: {
    id: 'Magazine.PitchReview',
    defaultMessage: `We’d like you to pitch your ideas to us. Login and use the form below to let us know what you would like to review. If it’s a specific Album, EP or Single, let us know what it is and why our audience needs to know about it. If it’s a more general style or genre you’d like to see more coverage on RA of, let us know why it is something our audience needs to hear about.<break></break><break></break>Resident Advisor currently maintains offices in London, Berlin, New York, Los Angeles and Tokyo, but we can't see or hear everything in our tentpole cities, much less locales where we lack access to on-the-ground reporting. As such, all pitches are encouraged, with an emphasis on Black artists and other scenes powered by minority and marginalised communities, without whom dance music would not exist.<break></break><break></break>For more information on a breakdown of our regular feature offering, check out this <contribute>guide to contributing to <i>RA</i></contribute>.<break></break><break></break>You can also read our  <integrity>editorial integrity guidelines here</integrity>.
    `,
  },
})

export default messages
