import PropTypes from 'prop-types'
import { PersonIcon } from '@resident-advisor/design-system'
import FormattedNumber from 'components/generic/formatted-number'
import MetaText from 'components/generic/meta-text'

const InterestedCount = ({ count = 0, ...props }) => {
  if (count === 0) {
    return null
  }

  return (
    <MetaText
      Icon={PersonIcon}
      text={<FormattedNumber>{count}</FormattedNumber>}
      {...props}
    />
  )
}

InterestedCount.propTypes = {
  count: PropTypes.number,
}

export default InterestedCount
