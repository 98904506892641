import Listing, { useListingQuery } from 'components/shared/listing'
import { useServerTime } from 'context/ServerTimeContext'
import get from 'lodash/get'

const useUpcomingEvents = ({ type, id }) => {
  const serverTime = useServerTime()
  const now = new Date(serverTime).toISOString()

  const { queryResult } = useListingQuery({
    type: Listing.Type.Event,
    baseFilters: [
      {
        type,
        value: id,
      },
      {
        type: Listing.Aggregations.DateRange,
        value: JSON.stringify({ gte: now }),
      },
    ],
    sortOrder: Listing.SortOrder.Ascending,
    sortField: Listing.SortField.Date,
  })

  const events = get(queryResult, 'data.listing.data') ?? []

  return {
    events,
    loading: queryResult?.loading,
    error: queryResult?.error,
  }
}

export default useUpcomingEvents
