const TICKET_VALID_TYPE = Object.freeze({
  valid: 'VALID',
  notYetOnSale: 'NOTYETONSALE',
  noLongerOnSale: 'NOLONGERONSALE',
  soldOut: 'SOLDOUT',
  quantityGreaterThanTicketsAvailable: 'QUANTITYGREATERTHANTICKETSAVAILABLE',
  statusChangedToNoSell: 'STATUSCHANGEDTONOSELL',
  dependency: 'DEPENDANCY', // this is misspelled in backend
  hideTicket: 'HIDETICKET',
  notYetOnSaleCombo: 'NOTYETONSALECOMBO',
})

export default TICKET_VALID_TYPE
