import IMAGE_TYPE from 'enums/image-type'
import EventDto from 'interfaces/gql/EventDto'

const getEventImage = (eventProps: EventProps = {}, imageType) => {
  if (eventProps?.newEventForm) {
    return eventProps.images?.find((i) => i.type === imageType)?.filename
  }

  return imageType === IMAGE_TYPE.flyerFront
    ? eventProps?.flyerFront
    : eventProps?.flyerBack
}

type EventProps = Pick<EventDto, 'images'> & {
  newEventForm?: boolean
  flyerFront?: string
  flyerBack?: string
}

export default getEventImage
