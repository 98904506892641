import { useIntl } from 'react-intl'
import normalizeNumber from 'lib/normalizeNumber'
import isNumber from 'lodash/isNumber'

const FormattedNumber = ({ children }) => {
  const intl = useIntl()

  return isNumber(children) ? normalizeNumber(children, intl) : children
}

export default FormattedNumber
