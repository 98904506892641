import { Text, variants } from '@resident-advisor/design-system'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { animation } from 'themes'

const SlashAnimation = ({
  isActive,
  size,
}: {
  isActive: boolean
  size: number
}) => {
  return (
    <MotionDiv
      variants={animationVariants(size)}
      initial={false}
      animate={isActive ? ACTIVE_STATE.active : ACTIVE_STATE.inactive}
    >
      <Text variant={variants.text.heading.xl}>&#824;</Text>
    </MotionDiv>
  )
}

const animationVariants = (size) => ({
  [ACTIVE_STATE.active]: {
    x: size / 2,
    y: size / 2,
    transition: animation.slashAnimation,
  },
  [ACTIVE_STATE.inactive]: {
    x: -size / 2,
    y: -size / 2,
    transition: animation.slashAnimation,
  },
})

const MotionDiv = styled(motion.div)`
  position: absolute;
`

enum ACTIVE_STATE {
  active = 'active',
  inactive = 'inactive',
}

export default SlashAnimation
