import PropTypes from 'prop-types'
import { variants } from '@resident-advisor/design-system'
import { EventsListingType } from 'components/events/sections/filtered-events-listing'
import TRACKING_EVENT from 'scripts/tracking/tracking-event'
import Tracking from 'scripts/tracking'
import getMixpanelProperties from 'scripts/tracking/helpers/getEventsListingMixpanelProperties'
import Listing from 'components/shared/listing'
import YearlyArchive from 'components/shared/yearly-archive'
import { usePersonalizationContext } from 'context/PersonalizationContext'

const YearlyArchiveWithEventsTracking = ({
  data,
  eventsListingType,
  baseFilterType,
  contentObjectId,
  contentObjectName,
  ...props
}) => {
  const { area } = usePersonalizationContext()
  const { data: mixpanelProperties } = getMixpanelProperties(data, {
    contentObjectType: baseFilterType,
    contentObjectId,
    contentObjectName,
    isArchive: eventsListingType === EventsListingType.Archive,
    popularEventsPresent: false,
    userArea: area,
  })

  Tracking.useTracking({
    mixpanel: {
      event: TRACKING_EVENT.viewEventListings,
      properties: mixpanelProperties,
    },
  })

  return <YearlyArchive data={data} {...props} />
}

YearlyArchiveWithEventsTracking.propTypes = {
  Card: PropTypes.func,
  SecondaryComponent: PropTypes.func,
  Pagination: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  secondaryComponentWrapBottom: PropTypes.bool,
  variant: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  baseFilterType: PropTypes.oneOf([
    Listing.Aggregations.Artist,
    Listing.Aggregations.Club,
    Listing.Aggregations.Promoter,
  ]),
  contentObjectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  contentObjectName: PropTypes.string,
  eventsListingType: PropTypes.string,
}

YearlyArchiveWithEventsTracking.defaultProps = {
  data: [],
  secondaryComponentWrapBottom: true,
  variant: variants.section.secondary,
  contentObjectId: '',
  contentObjectName: '',
}

export default YearlyArchiveWithEventsTracking
