const TYPE = {
  Event: 'EVENT',
  Review: 'REVIEW',
  Contributor: 'CONTRIBUTOR',
  News: 'NEWS',
}

const INDICES = {
  [TYPE.Event]: ['EVENT'],
  [TYPE.Review]: ['REVIEW'],
  [TYPE.Contributor]: ['FEATURE', 'NEWS', 'REVIEW'],
  [TYPE.News]: ['NEWS'],
}

const AGGREGATIONS = {
  Year: 'YEAR',
  Country: 'COUNTRY',
  Artist: 'ARTIST',
  DateRange: 'DATERANGE',
  Promoter: 'PROMOTER',
  Club: 'CLUB',
  Genre: 'GENRE',
  ReviewType: 'REVIEWTYPE',
  Language: 'LANGUAGE',
  Recommended: 'RECOMMENDED',
  Author: 'AUTHOR',
  Index: 'INDEX',
  EventType: 'EVENTTYPE',
  Pick: 'PICK',
  Area: 'AREA',
}

enum Fields {
  Id = 'id',
  EventDate = 'eventDate',
  InterestedCount = 'interestedCount',
  EventDatePosted = 'eventDatePosted',
  Date = 'date',
  Area = 'area',
  Areas = 'areas',
  ListingDate = 'listingDate',
  Genre = 'genre',
  ListingPosition = 'listingPosition',
  IsPick = 'isPick',
  EventType = 'eventType',
  TitleKeyword = 'titleKeyword',
  Score = 'score',
  NewsLocalityType = 'newsLocalityType',
  NewsType = 'type',
  NewsHeadline = 'newsHeadline',
  NewsViews = 'newsViews',
  Location = 'location',
}

const FIELDS = Object.freeze(Fields)

const INDEX_TYPES = {
  Event: 'event',
  Artist: 'artist',
  Club: 'club',
  Promoter: 'promoter',
  Label: 'label',
  Area: 'area',
  News: 'news',
  Review: 'review',
  Feature: 'feature',
}

const SORT_ORDER = {
  Ascending: 'ASCENDING',
  Descending: 'DESCENDING',
}

const SORT_FIELD = {
  Date: 'DATE',
  EventDate: 'EVENTDATE',
  ReviewDate: 'REVIEWDATE',
}

enum NewsLocalityType {
  Regional = 0,
  Countrywide = 1,
  Global = 2,
}

export {
  TYPE,
  AGGREGATIONS,
  INDICES,
  INDEX_TYPES,
  FIELDS,
  Fields,
  SORT_ORDER,
  SORT_FIELD,
  NewsLocalityType,
}
