import PropTypes from 'prop-types'
import { Meta, variants } from '@resident-advisor/design-system'
import Link from 'components/generic/link'

const MetaLink = ({
  href,
  text,
  color = 'primary',
  linkProps = {},
  ...props
}) => {
  return (
    <Meta {...props}>
      <Link
        variant={variants.text.label}
        href={href}
        color={color}
        {...linkProps}
      >
        {text}
      </Link>
    </Meta>
  )
}

MetaLink.propTypes = {
  Icon: PropTypes.elementType,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]).isRequired,
  href: PropTypes.string.isRequired,
  iconColor: PropTypes.string,
  color: PropTypes.string,
  linkProps: PropTypes.object,
}

export default MetaLink
