const ENTITY_TYPE = {
  Area: 'AREA',
  Artist: 'ARTIST',
  Club: 'CLUB',
  Exchange: 'EXCHANGE',
  Event: 'EVENT',
  Feature: 'FEATURE',
  FeatureSeries: 'FEATURESERIES',
  Film: 'FILM',
  FilmSeries: 'FILMSERIES',
  Label: 'LABEL',
  News: 'NEWS',
  Podcast: 'PODCAST',
  Promoter: 'PROMOTER',
  Review: 'REVIEW',
  PreReg: 'PREREG',
  Basket: 'BASKET',
  Order: 'ORDER',
}

export default ENTITY_TYPE
